<template>
  <div id="nav">
    <div class="header">
		<div class="logoImg">湖南奇葩乐游网络科技有限公司</div>
      <!-- <div class="logoImg"><router-link to="/"><img src="@/assets/images/nav-logo.png" /></router-link></div> -->
      <div class="tabList">
        <router-link to="/">首页</router-link>
        <!-- <a class="parentMenu" :class="{'router-link-active':tabStr=='about'}">
          走进奇葩
          <div class="menuList">
            <ul>
              <li><router-link to="/about/culture">奇葩文化</router-link></li>
              <li><router-link to="/about/introduce">奇葩介绍</router-link></li>
              <li><router-link to="/about/team">奇葩团队</router-link></li>
              <li><router-link to="/about/teamstyle">奇葩风采</router-link></li>
              <li><router-link to="/about/honor">奇葩荣誉</router-link></li>
              <li><router-link to="/about/history">奇葩历程</router-link></li>
            </ul>
          </div>
        </a>
        <a class="parentMenu" :class="{'router-link-active':tabStr=='games'}">
          产品介绍
          <div class="menuList">
            <ul>
              <li><router-link to="/games/culture">明星产品</router-link></li>
              <li><router-link to="/games/introduce">联研产品</router-link></li>
              <li><router-link to="/games/team">奇葩手游</router-link></li>
              <li><router-link to="/games/teamstyle">明星产品经理</router-link></li>
              <li><router-link to="/games/honor">明星产品</router-link></li>
            </ul>
          </div>
        </a> -->
        <router-link to="/news">新闻资讯</router-link>
        <router-link to="/joinus">加入我们</router-link>
        <router-link to="/connect">联系我们</router-link>
      </div>
    </div>
  </div>
  <router-view/>
  <div id="footer">
    <div class="footerBox">
      <div>
        <!-- <img src="@/assets/logo.png" /> -->
        <p><a href="https://beian.miit.gov.cn" target="_blank" style="color: #fff;"><span style="white-space:nowrap;">湘ICP备18003044号-2 Copyright© 2010-2021</span></a></p>
        <p>湖南奇葩乐游网络科技有限公司 版权所有</p>
        <p>联系人：刘女士</p>
        <p>合作邮箱：liuwei@7pa.com</p>
        <p>联系地址：长沙北辰三角洲游戏动漫产业园B1E1区1栋37层（长沙市开福区晴岚路68号）</p>
      </div>
      <div class="qrcode">
        <!-- <img src="@/assets/images/qrcode.jpg" /> -->
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'App',
  data(){
    return{
      tabStr:'',
    }
  },
  watch: {
    '$route':'getPath'
  },
  methods: {
    getPath(){
      let url = this.$route.path;
      if(url.indexOf('about/') != -1){
        this.tabStr = 'about';
      }else if(url.indexOf('games/') != -1){
        this.tabStr = 'games';
      }else{
        this.tabStr = '';
      }
      
    }
  }
}
</script>
<style lang="less">
  @import '@/assets/css/common';
</style>
<style lang="less" scoped>

#footer{
  width: 100%;height: 250px;background: #464646;color: #e0e8ea;
  .footerBox{
    width: 1200px;margin: 0 auto;display: flex;align-items: center;justify-content: space-between;height:100%;font-size:12px;line-height:22px;
    .qrcode{width: 180px;height:100%;display: flex;align-items: center;img{width: 100%;}}

  }
}
</style>
