import axios from 'axios'
import Qs from 'qs'

// if(process.env.NODE_ENV=='development'){
// 	axios.defaults.baseURL='https://game-server.7pa.com'
// }
// if(process.env.NODE_ENV=='production'){
// 	axios.defaults.baseURL='https://game-server.7pa.com'
// }
axios.defaults.timeout=5000;
axios.interceptors.request.use(response => {
	return response
})
export default {
	Get(url, data = {}) {
		return new Promise((resolve, reject) => {
			axios.get(url, {
				params:data
			}).then(res => {
				resolve(res)
			}).catch(err => {
				reject(err)
			})
		})
	},
	Post(url, data = {}) {
		return new Promise((resolve, reject) => {
			axios.post(url, Qs.stringify(data), {
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded'
				},
			}).then(res => {
				resolve(res)
			}).catch(err => {
				reject(err)
			})
		})
	}
}