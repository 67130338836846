<template>
  <div class="bannerTop"><img src="@/assets/images/contact-top.jpg"></div>
  <div class="section1">
      <div class="main">
          <a class="bigTitle">
              <h2>公司简介</h2>
              <span>Profile</span>
          </a>
          
      </div>
  </div>
  <div class="section2">
      <div class="main">
          <a class="bigTitle">
              <h2>产品介绍</h2>
              <span>Products</span>
          </a>
          
      </div>
  </div>
  <div class="section1">
      <div class="main">
          <a class="bigTitle">
              <h2>新闻动态</h2>
              <span>News</span>
          </a>
          
      </div>
  </div>
</template>

<script>
// @ is an alias to /src


export default {
  name: 'Home',
  components: {
      
    },
    methods: {
      
    },
}
</script>
<style lang="less">
  @import '@/assets/css/common';
</style>
<style lang="less" scoped>

</style>
