import { createRouter, createWebHistory } from 'vue-router'
// import Home from '@/views/Home2.vue'
// import Index from '@/views/Index.vue'
// import Connect from '@/views/Connect.vue'
import Joinus from '@/views/Joinus.vue'
// import News from '@/views/News.vue'

const routes = [
    {
		path: '/',
		name: 'Index',
		component: Joinus
    },
    {
		path: '/about/culture',
		name: '奇葩文化',
		component: Joinus
	},
	{
		path: '/about/introduce',
		name: '奇葩介绍',
		component: Joinus
	},
	{
		path: '/about/team',
		name: '奇葩团队',
		component: Joinus
	},
	{
		path: '/about/teamstyle',
		name: '奇葩风采',
		component: Joinus
	},
	{
		path: '/about/honor',
		name: '奇葩荣誉',
		component: Joinus
	},
	{
		path: '/about/history',
		name: '奇葩历程',
		component: Joinus
	},
	{
		path: '/joinus',
		name: '加入我们',
		component: Joinus
	},
	{
		path: '/news',
		name: '新闻资讯',
		component: Joinus
	},
	{
		path: '/connect',
		name: '联系我们',
		component: Joinus
	},
]
  
  const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
  })
  
  export default router