import service from './service'

export default {
	/**
	 * 资讯列表
	 * @param data
	 */
	newsList: (data) => {
		return service.Get('static/news.json', data);
	},
}